/* stylelint-disable selector-class-pattern */
/* stylelint-disable rule-empty-line-before */
*.primary,
*.yellow {
  color: var(--shades900);
  background: var(--mainprimary);
}

*.primary:hover,
*.yellow:hover {
  color: var(--shades900);
  background: var(--hoverprimary);
}

*.primary:active:focus,
*.yellow:active:focus {
  color: var(--shades900);
  background: var(--activeprimary);
}

button.lightblue {
  color: var(--maingreen);
  background: var(--lightblue);
}

button.lightblue:hover {
  color: var(--maingreen);
  background: var(--hoverlightblue);
}

button.lightblue:active:focus {
  color: var(--maingreen);
  background: var(--hoverlightblue);
}

button.lightgreen {
  color: var(--maingreen);
  background: var(--lightgreen);
}

button.lightgreen:hover {
  color: var(--maingreen);
  background: var(--hoverlightgreen);
}

button.lightgreen:active:focus {
  color: var(--maingreen);
  background: var(--hoverlightgreen);
}

button.lightred {
  color: var(--mainred);
  background: var(--lightred);
}

button.lightred:hover {
  color: var(--mainred);
  background: var(--hoverlightred);
}
button.lightred:active:focus {
  color: var(--mainred);
  background: var(--hoverlightred);
}
button.lightGrey {
  color: var(--shades900);
  background: var(--shades100);
}
button.lightGrey:hover {
  color: var(--shades900);
  background: var(--shades75);
}
button.lightGrey:active:focus {
  color: var(--shades900);
  background: var(--activegrey);
}
button.grey {
  color: var(--shades900);
  background: var(--maingrey);
}

button.grey:hover {
  color: var(--shades900);
  background: var(--hovergrey);
}

button.grey:active:focus {
  color: var(--shades900);
  background: var(--activegrey);
}

*.white {
  color: var(--shades900);
  background: var(--shades50);
  border: 1px solid var(--shades200, #e6e6eb);
}

*.white:hover {
  color: var(--shades900);
  background: var(--hoverwhite);
}

*.white:active:focus {
  color: var(--shades900);
  background: var(--activewhite);
}
*.plain {
  color: inherit;
}
*.plain:hover {
  color: inherit;
  background: inherit;
}

*.plain:hover {
  background: var(--hoverwhite);
}
*.green {
  color: var(--shades50);
  background: var(--maingreen);
}

*.green:hover {
  color: var(--shades50);
  background: var(--hovergreen);
}

*.green:active:focus {
  color: var(--shades50);
  background: var(--activegreen);
}
*.plain {
  color: inherit;
}

*.red {
  color: var(--shades50);
  background: var(--mainred);
}

*.red:hover {
  color: var(--shades50);
  background: var(--hoverred);
}

*.red:active:focus {
  color: var(--shades50);
  background: var(--activered);
}
