/* stylelint-disable property-no-vendor-prefix */
/* stylelint-disable order/properties-order */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 900;
  src: local('Neue Haas Grotesk Display Pro'),
    url('./assets/fonts/NeueHaasDisplayBlack.ttf') format('truetype');
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 800;
  src: local('Neue Haas Grotesk Display Pro'),
    url('./assets/fonts/NeueHaasDisplayBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 700;
  src: local('Neue Haas Grotesk Display Pro'),
    url('./assets/fonts/NeueHaasDisplayBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 600;
  src: local('Neue Haas Grotesk Display Pro'),
    url('./assets/fonts/NeueHaasDisplayBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 500;
  src: local('Neue Haas Grotesk Display Pro'),
    url('./assets/fonts/NeueHaasDisplayMedium.ttf') format('truetype');
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 400;
  src: local('Neue Haas Grotesk Display Pro'),
    url('./assets/fonts/NeueHaasDisplayRoman.ttf') format('truetype');
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 300;
  src: local('Neue Haas Grotesk Display Pro'),
    url('./assets/fonts/NeueHaasDisplayThin.ttf') format('truetype');
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 200;
  src: local('Neue Haas Grotesk Display Pro'),
    url('./assets/fonts/NeueHaasDisplayXThin.ttf') format('truetype');
}
@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  font-weight: 100;
  src: local('Neue Haas Grotesk Display Pro'),
    url('./assets/fonts/NeueHaasDisplayXXThin.ttf') format('truetype');
}

body {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Neue Haas Grotesk Display Pro', 'Work Sans', -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea:focus,
input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
button:focus,
select:focus,
.uneditable-input:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: 0 none !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px var(--shades75) inset !important;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px var(--shades75) inset !important;
}

:root {
  --background: #eef2f5;
  --mainsecondary: #272343;
  --mainprimary: #ebc345;
  --linkblue: #276ef1;
  --hoverprimary: linear-gradient(0deg, #ffd803, #ffd803),
    linear-gradient(0deg, #2723431a, #2723431a),
    linear-gradient(0deg, #fffc, #fffc);
  --activeprimary: linear-gradient(0deg, #2723431a, #2723431a),
    linear-gradient(0deg, #ffd803, #ffd803),
    linear-gradient(0deg, #ffd8034d, #ffd8034d);

  --maingrey: #bae8e8;
  --hovergrey: linear-gradient(0deg, #f3fbfb, #f3fbfb),
    linear-gradient(0deg, #0000001a, #0000001a),
    linear-gradient(0deg, #bae8e8, #bae8e8);
  --activegrey: linear-gradient(0deg, #f3fbfb, #f3fbfb),
    linear-gradient(0deg, #0000001a, #0000001a),
    linear-gradient(0deg, #bae8e8, #bae8e8);
  --mainwhite: linear-gradient(0deg, #f3fbfb, #f3fbfb),
    linear-gradient(0deg, #0000001a, #0000001a),
    linear-gradient(0deg, #bae8e8, #bae8e8);
  --hoverwhite: linear-gradient(0deg, #f3f3f8, #f3f3f8),
    linear-gradient(0deg, #fafafa, #fafafa);
  --activewhite: linear-gradient(0deg, #f3f3f8, #f3f3f8),
    linear-gradient(0deg, #fafafa, #fafafa);
  --lightblue: #bae8e8;
  --hoverlightblue: linear-gradient(0deg, #bae8e8, #6de6e6),
    linear-gradient(0deg, #0003, #0003), linear-gradient(0deg, #fffc, #fffc);
  --maingreen: #008f5d;
  --lightgreen: #f3faf7;
  --hoverlightgreen: linear-gradient(0deg, #f3faf7, #f3faf7),
    linear-gradient(0deg, #0003, #0003), linear-gradient(0deg, #fffc, #fffc);
  --hovergreen: linear-gradient(0deg, #008f5d, #008f5d),
    linear-gradient(0deg, #0003, #0003), linear-gradient(0deg, #fffc, #fffc);
  --activegreen: linear-gradient(0deg, #008f5d, #008f5d),
    linear-gradient(0deg, #0003, #0003),
    linear-gradient(0deg, #ffffffb3, #ffffffb3);
  --mainred: #ea3829;
  --lightred: #ffebe7;
  --hoverlightred: linear-gradient(0deg, #ffebe7, #ffebe7),
    linear-gradient(0deg, #0003, #0003), linear-gradient(0deg, #fffc, #fffc);

  --hoverred: linear-gradient(0deg, #ea3829, #ea3829),
    linear-gradient(0deg, #0003, #0003), linear-gradient(0deg, #fffc, #fffc);
  --activered: linear-gradient(0deg, #ea3829, #ea3829),
    linear-gradient(0deg, #ea3829, #ea3829),
    linear-gradient(0deg, #ffffffb3, #ffffffb3);
  --shades50: #fff;
  --shades75: #fafafa;
  --shades200: #e6e6eb;
  --shades100: #f3f3f8;
  --shades400: #b1b1bb;
  --shades500: #90909a;
  --shades600: #6d6d77;
  --shades700: #464650;
  --shades800: #22222a;
  --shades900: #000014;
  --overlay: blur(8px);
}
[contenteditable]:focus {
  outline: 0px solid transparent;
}

[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: #888;
  font-style: italic;
}

.overlay {
  backdrop-filter: var(--overlay);
}

.shadow-bg {
  background-color: #d9d9d9;
}

.select {
  @apply w-full flex items-center py-0 rounded bg-[#F1F3F4] capitalize;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroller {
  @apply scrollbar scrollbar-w-1  scrollbar-track-rounded-lg scrollbar-thumb-rounded-sm scrollbar-thumb-sifuse-main-primary  scrollbar-track-white;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 12px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
